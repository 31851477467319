import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNewsMediaOutlined = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 23 19"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#3F4347"
      d="M10.207 13.133c0-.524.424-.948.948-.948h6.494a.947.947 0 1 1 0 1.895h-6.494a.947.947 0 0 1-.948-.947"
    />
    <path
      fill="#3F4347"
      fillRule="evenodd"
      d="M11.155 12.185a.947.947 0 1 0 0 1.895h6.494a.947.947 0 1 0 0-1.895z"
      clipRule="evenodd"
    />
    <path
      fill="#3F4347"
      d="M10.207 10.518c0-.523.424-.948.948-.948h6.494a.947.947 0 0 1 0 1.895h-6.494a.947.947 0 0 1-.948-.947"
    />
    <path
      fill="#3F4347"
      fillRule="evenodd"
      d="M11.155 9.57a.947.947 0 0 0 0 1.895h6.494a.947.947 0 0 0 0-1.895z"
      clipRule="evenodd"
    />
    <path
      fill="#3F4347"
      fillRule="evenodd"
      d="M20.661 18.31c1.221 0 2.075-.926 2.075-2.25V.31H5.876V16.2c0 .805-.282 1.541-.738 2.11zM8.495 15.69h11.621V2.93H8.496zm-6.518 2.374c.272.157.584.246.915.246.56 0 1.065-.255 1.419-.663.327-.377.527-.886.527-1.446V3.685H.947V16.2c0 .809.416 1.509 1.03 1.863"
      clipRule="evenodd"
    />
    <path
      fill="#3F4347"
      fillRule="evenodd"
      d="M14.894 7.01a.947.947 0 0 0 0 1.895h2.842a.947.947 0 0 0 0-1.895zM14.915 4.433a.947.947 0 1 0 0 1.895h2.842a.947.947 0 0 0 0-1.895z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNewsMediaOutlined;
