import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWebOrderOutlined = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 21"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#3F4347"
      fillRule="evenodd"
      d="M2.943 2.008q.182.134.317.302c.1.134.19.29.257.46q.06.15.09.313l2 12c.053.32.32.56.667.56h16c.32 0 .613-.24.666-.56l2-12a.64.64 0 0 0-.16-.533.66.66 0 0 0-.507-.24H4.78a3 3 0 0 0-.47-.84A3.09 3.09 0 0 0 1.9.31h-.613A.66.66 0 0 0 .62.977a.66.66 0 0 0 .667.666h.64c.16 0 .32.027.453.08.202.062.395.16.563.285M6.571 4.93l1.349 8.093h12.707l1.35-8.093z"
      clipRule="evenodd"
    />
    <path
      fill="#3F4347"
      d="M18.943 18.31c0-1.093.906-2 2-2s2 .907 2 2-.907 2-2 2c-1.094 0-2-.907-2-2M5.61 18.31c0-1.093.906-2 2-2s2 .907 2 2-.907 2-2 2c-1.094 0-2-.907-2-2"
    />
  </svg>
);
export default SvgWebOrderOutlined;
