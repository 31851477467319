import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { Add, Minus } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import { useEffect, useState } from "react";
import FaqItem from "./faqItem";
import styles from "./index.module.scss";

export type FaqItemProperties = {
  question: string;
  answer: string;
  anchorId?: string;
};

export type FaqProperties = {
  header?: string;
  subHeader?: string;
};

export type FaqOwnProps = {
  faqItems: FaqItemProperties[];
  showAllItems: boolean;
  showAllText: string;
  showLessText: string;
  backgroundColor: "white" | "grey" | "blue" | "transparent";
  anchorId?: string;
  noGutter?: boolean;
};

export type FaqProps = FaqOwnProps & FaqProperties;

const Faq = ({
  faqItems,
  header,
  subHeader,
  showAllItems,
  showAllText,
  showLessText,
  backgroundColor,
  anchorId,
  noGutter,
}: FaqProps) => {
  const MAX_VISIBLE_ITEMS: number = 5;

  useEffect(() => {
    if (showAllItems === true) {
      //  setFaqItemsList((prev) => [...faqItems]);
    } else {
      // setFaqItemsList((prev) => [...faqItems.slice(0,MAX_VISIBLE_ITEMS)]);
    }
  }, []);
  
  const getItemBackground = () => {
    switch (backgroundColor) {
    case "blue": return "white";
    case "grey": return "blue";
    case "white": return "grey";
    case "transparent": return "blue";
    }
  };

  const [showMoreItemsButton, setShowMoreItemsButton] = useState(showAllItems);
  const renderCards = () => {
    const listItems: JSX.Element[] = faqItems.map((item, index) => {
      const isHidden: boolean = index >= MAX_VISIBLE_ITEMS && !showAllItems;
      const classnames = cn(styles.faq__item, {
        [styles["faq__item--hidden"]]: isHidden,
      });
      return (
        <FaqItem
          className={classnames}
          key={index}
          header={item.question}
          body={item.answer}
          background={getItemBackground()}
          anchorId={item.anchorId}
        />
      );
    });
    return listItems;
  };

  const showMoreItemsClick = () => {
    if (showMoreItemsButton === false) {
      setShowMoreItemsButton(true);
    } else {
      setShowMoreItemsButton(false);
    }
  };
  const classnamesCointainer = cn({
    [styles["faq--showall"]]: showMoreItemsButton && !showAllItems,
    [styles[`faq__container_${backgroundColor}`]]: backgroundColor,
  });
  return (
    <div
      id={anchorId}
      className={classnamesCointainer}>
      <Container pageGutter={noGutter ? 0 : undefined}>
        <Box
          component="section"
          container
          flexDirection="column">
          {header && (
            <EpiProperty<FaqProperties> name="header">
              <Typography
                textAlign="center"
                mb={[10, 12, 14]}
                variant="heading1"
                asElement="h2"
              >
                {header}
              </Typography>
            </EpiProperty>
          )}
          {subHeader && (
            <EpiProperty<FaqProperties> name="subHeader">
              <Typography
                mb={4}
                mt={6}
                variant="heading3"
                asElement="h3"
              >
                {subHeader}
              </Typography>
            </EpiProperty>
          )}

          {faqItems && renderCards()}

          {faqItems && faqItems.length > MAX_VISIBLE_ITEMS && showAllItems === false && (
            <Box
              justifyContent="center"
              pt={10}>
              {showMoreItemsButton === false && (
                <TextButtonIcon
                  color="charcoal"
                  onClick={showMoreItemsClick}
                  label={showAllText}
                  direction="right"
                >
                  <Add />
                </TextButtonIcon>
              )}
              {showMoreItemsButton === true && (
                <TextButtonIcon
                  color="charcoal"
                  onClick={showMoreItemsClick}
                  label={showLessText}
                  direction="right"
                >
                  <Minus />
                </TextButtonIcon>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
};

export type ProvidedFaqProps = OptimizelyBlockProps<FaqProperties> & FaqProps;

const ProvidedFaq = ({
  blockId,
  inEditMode,
  properties,
  anchorId,
  ...rest
}: ProvidedFaqProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
    anchorId={anchorId}
  >
    {(optimizlyProperties) => <Faq
      anchorId={anchorId}
      {...rest}
      {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedFaq;
