import SnwButtonContainer from "@@/components/Button/SnwButtonContainer";
import { SnwButtonType } from "@@/components/Button/buttonProps";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import { useEffect, useState } from "react";
import HeroSplit from "../Split";
import styles from "./index.module.scss";

export type Align = "left" | "center" | "right";

export type HeroFullWidthProperties = {
  label?: string;
  header?: string;
  intro?: string;
  image: PictureData;
};

export type HeroFullWidthOwnProps = {
  alignment: Align;
  buttons: SnwButtonType[];
};

export type HeroFullWidthProps = HeroFullWidthOwnProps &
HeroFullWidthProperties;

const HeroFullWidth = ({
  header,
  alignment = "center",
  label,
  intro,
  buttons,
  image,
}: HeroFullWidthProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const [updatedButtons, setUpdatedButtons] = useState<SnwButtonType[]>(buttons);
  const containerClassnames = cn(
    { [styles["hero--hasnotch"]]: true },
    styles.herocontainer,
  );
  const stylesHero = cn(styles.hero, {
    [styles[`hero--${alignment}`]]: alignment,
  });

  const updateButtonsForSmallScreen = (button: SnwButtonType): SnwButtonType => {
    if (button.variant === "tertiary" && button.color === "white") {
      return {
        ...button,
        color: "charcoal",
      };
    }
    return button;
  };

  useEffect(() => {
    setUpdatedButtons(buttons.map(updateButtonsForSmallScreen));
  }, []);


  

  return (
    <>
      <Container
        size="full"
        pageGutter={0}
        className={containerClassnames}>
        <EpiProperty<HeroFullWidthProperties> name="image">
          <div className={stylesHero}>
            <Box
              positionRoot
              container
              pb={10}>
              <Box
                container
                justifyContent="center"
                width="full"
                height="full"
                py={!intro && (!buttons || buttons?.length < 1) ? 36 : 18}
                className={cn(styles.hero_wrapper, 
                  "hero-image",
                  {
                    [(styles as any)[`hero_wrapper--${alignment}`]]: alignment,
                  })}
              >
                <div className={styles.hero_content}>
                  {label && (
                    <Box mb={6}>
                      <div className={styles.hero_content__br}>
                        <EpiProperty<HeroFullWidthProperties> name="label">
                          <Typography
                            textcolor="light"
                            variant="heading5"
                            asElement="div"
                          >
                            {label}
                          </Typography>
                        </EpiProperty>
                        <hr className={styles.line} />
                      </div>
                    </Box>
                  )}

                  <EpiProperty<HeroFullWidthProperties> name="header">
                    <Typography
                      textcolor="light"
                      variant="heading1"
                      asElement="h1"
                    >
                      {header}
                    </Typography>
                  </EpiProperty>
                  {intro && (
                    <EpiProperty<HeroFullWidthProperties> name="intro">
                      <Typography
                        mt={4}
                        textcolor="light"
                        variant="body">
                        {intro}
                      </Typography>
                    </EpiProperty>
                  )}
                  {buttons && buttons.length > 0 && (
                    <Box
                      mt={[6, 8, 9]}
                      className={cn(styles.hero_content__buttonwrapper, {
                        [(styles as any)[
                          `hero_content__buttonwrapper--${alignment}`
                        ]]: alignment,
                      })}
                    >
                      <SnwButtonContainer buttons={isSmall ? updatedButtons : buttons} />
                    </Box>
                  )}

                </div>
              </Box>
              <Picture
                className={styles.hero_picture}
                imageData={image}
                aspectRatio={["1 / 2", "5 / 4", "21 / 9"]}
                sizes={[[200, 400, 600, 800, 1200, 1600, 3200, 3840], [200, 400, 600, 800, 1200, 1600, 3200, 3840], [200, 400, 600, 800, 1200, 1600, 3200, 3840]]}
                lazySizes={false}
                loading="eager"
                sourcesSizes="(min-widht: 1920px) 1920px, 100vw"
              />
            </Box>
          </div>
        </EpiProperty>
      </Container>
      <HeroSplit
        header={header}
        label={label}
        body={intro}
        alignment="left"
        buttons={updatedButtons}
        image={image}
      />
    </>
  );
};

export type ProvidedHeroFullWidthCardProps =
  OptimizelyBlockProps<HeroFullWidthProperties> & HeroFullWidthProps;

const ProvidedHeroFullWidthCard = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedHeroFullWidthCardProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <HeroFullWidth
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedHeroFullWidthCard;
