import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import { default as Typhography, default as Typography } from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import styles from "../index.module.scss";

type CollageLinkListEditableProps = {
    header: string;
    linkItems?: LinkData[];
};

export type CollageLinkListProps = CollageLinkListEditableProps & {
    dataVariant: string;
    dataPosition: number;
};

const CollageLinkList = ({ header, linkItems, dataVariant, dataPosition } : CollageLinkListProps) => {

  const isMobileDevice = useIsBreakpointOrBelow(ScreenSize.SMALL);

  const renderLink = (link :LinkData, index: number) => {
    if (!!link.href && !!link.text) {
      return (
        <a
          key={index}
          href={link.href}
          className={styles.collage_hero__item_list__link}
          title={link.title}
          target={link.target}
          data-variant={dataVariant}
          data-position={dataPosition}
          data-heading={link.text}
          data-click-text={link.text}
        >
          <Typography
            mr={3}
            variant={isMobileDevice ? "information" : "intro" }>{link.text}</Typography>
          <ArrowRight
            className={styles.collage_hero__icon}
            width={16}
            height={16} />
        </a>
      );
    }
  };

  return (
    <Box
      className={styles.collage_hero__item_list}
      flexDirection="column"
      alignItems="flex-start"
      justifyContent={"center"}
      height={"full"}
      backgroundColor="ComplementaryBeigeTint04">
      <EpiProperty<CollageLinkListEditableProps> name="header">
        <Box>
          <Typhography
            variant={isMobileDevice ? "heading6" : "heading5"}
            asElement="h2">{header}</Typhography>
          <Box className={styles.collage_hero__item_list__line}/>
        </Box>
      </EpiProperty>
      <EpiProperty<CollageLinkListEditableProps> name="linkItems">
        {linkItems && linkItems.map((link, index) => {
          return renderLink(link, index);
        })}
      </EpiProperty>
    </Box>
  );
};

type ProvidedCollageLinkListProps =
OptimizelyBlockProps<CollageLinkListEditableProps> & CollageLinkListProps;

const ProvidedCollageLinkList = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedCollageLinkListProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <CollageLinkList
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedCollageLinkList;