import { SnwButtonType } from "@@/components/Button/buttonProps";
import SnwButton from "@@/components/Button/SnwButton";
import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import useScrollPosition from "@@/hooks/use-scroll-position";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { useEffect, useRef, useState } from "react";
import Accordion from "..";
import Styles from "./ctaSidebar.module.scss";

type CTASidebarEditableProps = {
  button?: SnwButtonType;
  header: string;
  body: string;
  showSticky: boolean;
};

type CTASidebarProps = CTASidebarEditableProps;

const CTASidebar = ({
  header,
  body,
  button,
  showSticky = true,
}: CTASidebarProps) => {
  const [sticky, setSticky] = useState<boolean>(false);
  const ref = useRef(null);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    if (scrollPosition && ref.current) {
      const { offsetTop } = ref.current;
      setSticky(scrollPosition > (offsetTop + 60));
    }
  }, [ref, scrollPosition]);

  return (
    <div
      ref={ref}>
      <Accordion
        header={header}
        backgroundColor={"complementarty-blue"}>
        <EpiProperty<CTASidebarProps> name="body">
          <Box>
            <RichText body={body} />
          </Box>
        </EpiProperty>
        {(button && button.link.text && button.link.href) && (
          <Box
            className={Styles.cta_full}
            width={"full"}
            mt={6}>
            <Box className={Styles.button_container}>
              <EpiProperty<CTASidebarProps> name="button">
                <SnwButton
                  variant="tertiary"
                  link={button.link} />
              </EpiProperty>
            </Box>
          </Box>
        )}
        {showSticky && (button && button.link.text && button.link.href) && (
          <Box
            className={sticky ? Styles.sticky_button : Styles.hidden}
            backgroundColor="White"
            py={4}
            px={6}>
            <SnwButton
              link={button.link}
              variant="primary" />
          </Box>
        )}
      </Accordion>
    </div>
  );
};

export type ProvidedCTASidebarProps =
  & OptimizelyBlockProps<CTASidebarProps> &
  CTASidebarProps;

const ProvidedCTASidebar = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedCTASidebarProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}>
    {(optimizlyProperties) => (
      <CTASidebar
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedCTASidebar;
