import Box from "@@/elements/Box";
import { ArrowRight, Close } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import { useScrollLock } from "@@/hooks/use-scroll-lock";
import cn from "classnames";
import { useEffect, useState } from "react";
import TextButtonIcon from "../../Button/TextButtonIcon";
import { LanguageSelectorProps } from "../types";
import styles from "./index.module.scss";

type MarketProps = {
  closeModal?: () => void;
  open: boolean;
  addTracking?: boolean;
};

type Props = LanguageSelectorProps & MarketProps;

const MarketSelectorModal = ({
  logoUrl,
  logoAltText,
  header,
  items,
  closeModalLabel,
  closeModal,
  open,
  addTracking,
}: Props) => {
  const { lock, unlock } = useScrollLock();
  const [scrolledToBottom, setIsScrolledToBottom] = useState<boolean>();
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    const marketList = document.getElementById("market-list");
    setHasVerticalScrollbar(
      marketList?.scrollHeight > marketList?.clientHeight,
    );
  }, []);

  function handleScroll(event) {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setIsScrolledToBottom(true);
    } else setIsScrolledToBottom(false);
  }

  useEffect(() => {
    if (open) {
      lock();
    } else unlock();
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [open]);

  return (
    <>
      <div
        onClick={closeModal}
        className={cn(styles.overlay, { [styles.open]: open })}
      />
      <Box
        aria-modal
        aria-labelledby={header}
        tabIndex={-1}
        role="dialog"
        px={10}
        pt={10}
        pb={18}
        className={cn(styles.modal, { [styles.open]: open })}
        backgroundColor="White"
      >
        {hasVerticalScrollbar && !scrolledToBottom && (
          <Box className={styles.market_gradient} />
        )}
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <img
            className={styles.logo}
            src={logoUrl}
            alt={logoAltText} />
          <TextButtonIcon
            data-dismiss="modal"
            aria-label={closeModalLabel}
            onClick={closeModal}
            direction="right"
            dense
            label={closeModalLabel}
          >
            <Close />
          </TextButtonIcon>
        </Box>
        <Box
          width="full"
          flexDirection="column"
          alignItems="center">
          {header && (
            <Box pb={8}>
              <Typography
                variant="heading5"
                asElement="div">
                {header}
              </Typography>
              <hr className={styles.line} />
            </Box>
          )}

          <ul
            onScroll={handleScroll}
            id="market-list"
            className={styles.list}>
            {items?.map((item, index) => (
              <li
                key={index}
                className={styles.list_item}>
                {item.active ? (
                  <a
                    aria-label="current market"
                    className={styles.active_item}
                    href={item.link.href}
                    target={item.link.target}
                    title={item.link.title}
                    data-menu-type={addTracking ? "menu utilities" : undefined}
                    data-menu-level={addTracking ? "2" : undefined}
                    data-menu-text={addTracking ? item.link.text : undefined}
                    data-menu-path={addTracking ? item.link.href : undefined}
                  >
                    <div className={styles.icon_container}>
                      <ArrowRight />
                    </div>
                    <Typography
                      variant="heading5"
                      asElement="div">
                      {item.link.text}
                    </Typography>
                  </a>
                ) : (
                  <a
                    href={item.link.href}
                    target={item.link.target}
                    title={item.link.title}
                    data-menu-type={addTracking ? "menu utilities" : undefined}
                    data-menu-level={addTracking ? "2" : undefined}
                    data-menu-text={addTracking ? item.link.text : undefined}
                    data-menu-path={addTracking ? item.link.href : undefined}
                  >
                    <Typography
                      className={styles.item}
                      color="ComplementaryCharcoal"
                      variant="heading5"
                      asElement="div"
                    >
                      {item.link.text}
                    </Typography>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </Box>
      </Box>
    </>
  );
};

export default MarketSelectorModal;
