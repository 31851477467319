import SnwButton from "@@/components/Button/SnwButton";
import LanguageSelector from "@@/components/MarketSelector";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import {
  ChevronRight,
  Contact,
  Find,
  Globe,
  NewsKnowledge,
  NewsMedia,
  Person,
  WebOrder,
} from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import useScrollDirection from "@@/hooks/use-scroll-direction";
import useScrollPosition from "@@/hooks/use-scroll-position";
import cn from "classnames";
import { useEffect, useState } from "react";
import { TopMenuProps } from "../headerProps";
import styles from "./topMenu.module.scss";

export const GetCorrectIcon = (name: string) => {
  switch (name) {
  case "globe":
    return <Globe />;
  case "contact":
    return <Contact />;
  case "person":
    return <Person />;
  case "weborder":
    return <WebOrder />;
  case "find":
    return <Find />;
  case "news_media":
    return <NewsMedia />;
  case "news_knowledge":
    return <NewsKnowledge />;
  default:
    return <Globe />;
  }
};

const TopMenu = ({
  company, items, languageSelector,
}:TopMenuProps) => {
  const scrollDirection = useScrollDirection();
  const scrollPosition = useScrollPosition();
  const [open, setOpen] = useState(false);
  const classNames = cn(styles.header, {
    [(styles as any)[`header_${company}`]]: company,
  });
  useEffect(() => {
    const menuElement = document.querySelector("#top-menu");
    menuElement.parentElement?.classList.add("top-menu");
  }, []);

  useEffect(() => {
    const menuElement = document.querySelector("#top-menu");
    const hideMenu = scrollPosition > 150;

    if (hideMenu && scrollDirection !== "down") {
      menuElement.parentElement?.classList.add("top-menu-sticky");
    } else if (scrollPosition === 0) {
      menuElement.parentElement?.classList.remove("top-menu-sticky");
    }

    if (scrollDirection === "down" && hideMenu) {
      menuElement.parentElement?.classList.add("top-menu-hide");
      setOpen(false);
    }
    if (scrollDirection === "up") {
      menuElement.parentElement?.classList.remove("top-menu-hide");
    }
  }, [scrollDirection, scrollPosition]);

  return (
    <Container
      backgroundColor="White"
      id="top-menu"
      size="full"
      pageGutter={0}
    >
      <header className={classNames}>
        <Box
          className={styles.content_width}
          justifyContent="flex-end"
          alignItems="center"
          flexDirection="row"
          height="full"
        >
          {items.map((item, index) => (
            <a
              key={index}
              className={styles.item}
              href={item.link.href}
              target={item.link.target}
              aria-label={item.iconAltText}
              data-menu-type="menu utilities"
              data-menu-level="1"
              data-menu-text={item.link.text}
              data-menu-path={item.link.href}
            >
              {item.link.text && <Typhography
                pr={2}
                variant="toolbarMenu">{item.link.text}</Typhography>}
              {GetCorrectIcon(item.icon)}
            </a>
          ))}
          {languageSelector && (
            <LanguageSelector
              languageSelector={languageSelector}
              open={open}
              setOpen={setOpen}
              variant="header"
              company={company}
              addTracking
            />
          )}
        </Box>
      </header>
    </Container>
  );
};

export default TopMenu;

export const TopMenuMobile = ({
  company, languageSelector, items, onClick, mobileButton,
}: TopMenuProps) => {
  const mobileClassNames = cn(styles.mobile_container, {
    [(styles as any)[`header_${company}`]]: company,
  });
  return (
    <div className={mobileClassNames}>
      {items.map((item, index) => (
        <a
          key={index}
          className={styles.item}
          href={item.link.href}
          target={item.link.target}
          aria-label={item.iconAltText}
          data-menu-type={"menu utilities"}
          data-menu-level={"1"}
          data-menu-text={item.link.text}
          data-menu-path={item.link.href}
        >
          {item.link.text && <Typhography
            className={styles.market_selector__mobile}
            pl={2}
            variant="toolbarMenu">{item.link.text}</Typhography>}
          {GetCorrectIcon(item.icon)}
        </a>
      ))}
      {languageSelector?.current && (
        <button
          onClick={onClick}
          className={styles.market_item}
          type="button"
          data-menu-type={"menu utilities"}
          data-menu-level={"1"}
          data-menu-text={languageSelector.current}
          data-menu-path=""
        >
          <Box flexDirection="row-reverse">
            <Typhography 
              className={styles.market_selector__mobile}
              px={2} 
              variant="toolbarMenu"
            >
              {languageSelector.current}</Typhography>
            <Globe />
          </Box>
          <Box className={styles.icon_container}>
            <ChevronRight />
          </Box>
        </button>
      )}
      {mobileButton && (
        <Box className={styles.mobile_buttons}>
          <SnwButton {...mobileButton} />
        </Box>)}
    </div>
  );
};
