import { SVGProps } from "react";
import * as Icons from "./icons";
import * as PictogramIcons from "./pictogram";

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const components = {
  // General icons
  essentials: Icons.EssentialsIcon,
  optimize: Icons.OptimizeIcon,
  inspire: Icons.InspireIcon,
  globe: Icons.ProcessIcon1,
  manufacturing: Icons.ProcessIcon2,
  recycling: Icons.ProcessIcon3,
  "raw-materials": Icons.ProcessIcon4,
  person: Icons.Person,
  find: Icons.Find,
  contact: Icons.Contact,
  pin: Icons.Pin,
  user: Icons.User,
  global: Icons.Globe,
  weborder: Icons.WebOrder,
  news_media: Icons.NewsMedia,
  news_knowledge: Icons.NewsKnowledge,
  news_knowledge_outlined: Icons.NewsKnowledgeOutlined,
  news_media_outlined: Icons.NewsMediaOutlined,
  contact_outlined: Icons.ContactOutlined,
  person_outlined: Icons.PersonOutlined,
  weborder_outlined: Icons.WebOrderOutlined,
  // Pictogram Icons
  ...PictogramIcons,
};

export type IconTypes = keyof typeof components;

type IconProps = {
  name: IconTypes;
};

const Icon = ({ name, ...props } : IconProps & SVGRProps & SVGProps<SVGSVGElement>) => {
  const IconComponent = components[name];

  if (!IconComponent) return <></>;

  return <IconComponent {...props} />;
};

export default Icon;
