import ResponsiveValue from "@@/base/responsive-value";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { ScreenSize, useIsBreakpointAndAbove, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import cn from "classnames";
import CollageImage, { CollageImageProps } from "./Image";
import collageStyles from "./index.module.scss";
import CollageLinkList, { CollageLinkListProps } from "./LinkList";

export type CollageHeroProps = {
  collageImages: CollageImageProps[];
  collageLinkList?: CollageLinkListProps[];
};

const getAspectRatio = (isSmallDevice, isLargeDevice, numberOfItems) :ResponsiveValue<string> => {
  if (numberOfItems === 2) {
    return "16/9";
  }
  if (isSmallDevice && !isLargeDevice || !isSmallDevice && isLargeDevice) {
    return "2/1";
  }

  return "21/9";
};

const CollageHero = ({ collageImages, collageLinkList } :CollageHeroProps) => {
  const numberOfItems = collageImages.length + (!!collageLinkList ? collageLinkList.length : 0);
  const heroClasses = cn(collageStyles.collage_hero, {
    [collageStyles.collage_hero__three] : numberOfItems > 2,
  });

  const isSmallDevice = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const isLargeDecive = useIsBreakpointAndAbove(ScreenSize.LARGE);

  const aspectRatio = getAspectRatio(isSmallDevice, isLargeDecive, numberOfItems);

  const getVariantString = () => {
    if (collageImages.length === 3) {
      return "collage-3-image";
    } else if (collageImages.length === 2) {
      return  collageLinkList && collageLinkList.length > 0 ? "collage-2-image-linklist" : "collage-2-image";
    }
    return collageLinkList && collageLinkList.length > 0 ? "collage-image-linklist" : "collage-image";
  };

  return (
    <Container
      size="full"
      pageGutter={0}>
      <EpiContent>
        <Box
          className={heroClasses}
          width={"full"}>
          {collageImages.map((item, index) => (
            <CollageImage
              key={index}
              link={item.link}
              header={item.header}
              image={item.image}
              aspectRatio={aspectRatio}
              useH1={index === 0}
              itemPosition={index + 1}
              dataVariant={getVariantString()} />
          ))}
          {collageLinkList && collageLinkList.map((item, index) => (
            <CollageLinkList
              key={index}
              {...item}
              dataVariant={getVariantString()}
              dataPosition={collageImages.length + 1} />
          ))}
        </Box>
      </EpiContent>
    </Container>
  );
};

type ProvidedCollageHeroProps =
  OptimizelyBlockProps<CollageHeroProps> &
  CollageHeroProps;

const ProvidedCollageHero = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedCollageHeroProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <CollageHero
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedCollageHero;