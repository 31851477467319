import ResponsiveValue from "@@/base/responsive-value";
import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import styles from "../index.module.scss";

type CollageImageEditableProps = {
    header: string;
    link: LinkData;
};
export type CollageImageProps = CollageImageEditableProps & {
    image: PictureData;
    useH1?: boolean;
    aspectRatio?: ResponsiveValue<string>;
    itemPosition: number;
    dataVariant: string;
};

const CollageImage = ({ header, image, link, useH1 = false, aspectRatio = "16/9", itemPosition, dataVariant } : CollageImageProps )=> {
  const itemClasses = cn(styles.collage_hero__item, {
    [styles.collage_hero__item_wide]: aspectRatio === "21/9",
    [styles.collage_hero__item_square]: aspectRatio === "2/1",
  });
  const isMobileDevice = useIsBreakpointOrBelow(ScreenSize.SMALL);

  return (
    <Box
      className={itemClasses}>
      <Picture
        className={styles.collage_hero__item_image}
        imageData={image}
        aspectRatio={aspectRatio}
        loading="eager"
        lazySizes={false}
        sizes={[
          300, 600, 900, 1200, 1800,
        ]}
      />
      <Box
        className={styles.collage_hero__item_content}>
        <Box className={styles.collage_hero__item_shadow}>
          <Box className={styles.collage_hero__item_container}>
            <EpiProperty<CollageImageEditableProps> name="header">
              <Typhography
                className={styles.collage_hero__item_content__text}
                asElement={useH1 ? "h1" : "h2"}
                variant={isMobileDevice ? "body" : "intro"}>{header}</Typhography>
            </EpiProperty>
            <Box
              flexDirection="row"
              alignItems="center">
              <Typhography
                mr={3}
                asElement="p"
                className={styles.collage_hero__item_content__text}
                variant="button">{link.text}</Typhography>
              <ArrowRight
                className={cn(styles.collage_hero__item_content__arrow, styles.collage_hero__icon)}
                height={16}
                width={16} />
            </Box>
          </Box>
        </Box>
      </Box>
      <a
        className={styles.collage_hero__item_link}
        href={link.href}
        title={link.title}
        data-variant={dataVariant}
        data-position={itemPosition}
        data-heading={header}
        data-click-text={link.text}>
      </a>
    </Box>
  );
};
type ProvidedCollageImageProps =
OptimizelyBlockProps<CollageImageEditableProps> & CollageImageProps;

const ProvidedCollageImage = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedCollageImageProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <CollageImage
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedCollageImage;