import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNewsKnowledgeOutlined = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 21"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="#3F4347" clipPath="url(#newsKnowledge-outlined_svg__a)">
      <path d="M11.147 18.26h-6.41c-.37 0-.7.2-.89.51-.18.32-.18.71 0 1.03s.52.51.89.51h6.41c.37 0 .7-.2.89-.51.18-.32.18-.71 0-1.03s-.52-.51-.89-.51M14.147 3.29A8 8 0 0 0 9.677.5a8 8 0 0 0-5.23.62 7.97 7.97 0 0 0-3.7 3.75 8.02 8.02 0 0 0-.53 5.24 7.9 7.9 0 0 0 2.86 4.42v1.7c0 .26.1.52.29.7.19.19.44.29.7.29h7.75c.26 0 .52-.1.7-.29.19-.19.29-.44.29-.7v-1.7c.96-.74 1.73-1.69 2.27-2.78.53-1.09.81-2.28.81-3.5 0-1.81-.62-3.56-1.74-4.97zm-.87 4.97c0 .81-.19 1.61-.54 2.34a5.4 5.4 0 0 1-1.52 1.87l-1.01.79v1.36h-4.5v-1.36l-1.01-.79a5.35 5.35 0 0 1-1.92-2.97c-.28-1.18-.16-2.42.36-3.51.52-1.1 1.39-1.98 2.48-2.52 1.09-.53 2.32-.68 3.51-.41 1.18.26 2.24.92 3 1.87a5.32 5.32 0 0 1 1.17 3.33z" />
    </g>
    <defs>
      <clipPath id="newsKnowledge-outlined_svg__a">
        <path fill="#fff" d="M-.003.31h15.9v20h-15.9z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNewsKnowledgeOutlined;
