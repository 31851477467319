import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import SnwButton from "../Button/SnwButton";
import RichText from "../RichText";
import styles from "./index.module.scss";

type HiglightCtaEditableProps = {
  header: string;
  body: string;
};

type HighlightCtaOwnProps = {
  link?: LinkData;
  anchorId?: string;
};

type HighlightCtaProps = HighlightCtaOwnProps & HiglightCtaEditableProps;

const HighlightCta = ({ header, body, link, anchorId } : HighlightCtaProps) => {

  return (
    <Box
      id={anchorId}
      backgroundColor="ComplementaryBlue">
      <Container
        pageGutterY={[10, 14, 14]}>
        <Box
          width="full"
          className={styles.highlight_cta}>
          <Box className={styles.highlight_cta__content}>
            <EpiProperty<HiglightCtaEditableProps> name="header">
              <Typhography
                variant="heading3"
                asElement="h2"
                mb={[4, 4, 2]}
                color="White">{header}</Typhography>
            </EpiProperty>
            <EpiProperty<HiglightCtaEditableProps> name="body">
              <Box color="White">
                <RichText body={body} />
              </Box>
            </EpiProperty>
          </Box>
          <Box mt={[6, 10, 0]}>
            <SnwButton
              variant="tertiary"
              link={link} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export type ProvidedHighlightCtaProps =
  OptimizelyBlockProps<HighlightCtaProps> & HighlightCtaProps;

const ProvidedHighlightCta = ({
  blockId,
  inEditMode,
  anchorId,
  properties,
  ...rest
}: ProvidedHighlightCtaProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    anchorId={anchorId}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <HighlightCta
        anchorId={anchorId}
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);
  
export default ProvidedHighlightCta;