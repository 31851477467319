import Box from "@@/elements/Box";
import Typography from "@@/elements/Typography";
import LanguageSelector from "../MarketSelector";

import Container from "@@/elements/Container";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { useState } from "react";
import { FooterBottomBarProps } from "./FooterProps";
import styles from "./index.module.scss";

const FooterBottomBar = ({
  copyrightLink,
  bottomLinkItems,
  languageSelector,
}: FooterBottomBarProps) => {
  const [open, setOpen] = useState(false);
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  return (
    <Box className={styles.footer_bottom_bar}>
      <Container
        pageGutterY={0}
        component="div">
        <Box
          justifyContent="space-between"
          alignItems="center"
          py={6}
          gap={2}
          flexWrap="wrap"
          flexDirection={["column", "column", "row"]}
        >
          {copyrightLink.href && (
            <a
              href={copyrightLink.href}
              target={copyrightLink.target}
              className={styles.footer__link}
            >
              <Typography
                variant="information"
                asElement="div"
                textcolor="light"
              >
                {copyrightLink.text}
              </Typography>
            </a>
          )}
          {!copyrightLink.href && (
            <Box color="White">
              <Typography
                variant="information"
                asElement="span"
                textcolor="light"
              >
                {copyrightLink.text}
              </Typography>
            </Box>
          )}

          <Box
            flexDirection="row"
            flexWrap="wrap">
            {languageSelector?.current && !isSmall && (
              <>
                <LanguageSelector
                  languageSelector={languageSelector}
                  open={open}
                  variant="footer"
                  setOpen={setOpen}
                />
                <Box alignItems="center">
                  <Box
                    className={styles.divider}
                    mx={2} />
                </Box>
              </>
            )}
            {bottomLinkItems.map((item, index) => (
              <Box
                key={index}
                alignItems="center"
                className={styles.bottom_link_wrapper}>
                <a
                  href={item.href}
                  target={item.target}
                  className={styles.footer__link}
                >
                  <Typography
                    asElement="span"
                    variant="information">
                    {item.text}
                  </Typography>
                </a>
                <Box
                  className={styles.divider}
                  mx={2} />
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterBottomBar;
