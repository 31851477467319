import { ColorToken } from "@@/design-tokens";
import Box from "@@/elements/Box";
import { Globe } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import cn from "classnames";
import { Dispatch, SetStateAction } from "react";
import { Companies } from "../Header/headerProps";
import LanguageSelectorDropdown from "./Language";
import MarketSelectorModal from "./Market";
import SelectIcon from "./selectIcon";
import styles from "./styles.module.scss";
import { LanguageSelectorProps, SelectorType, SelectorVariant } from "./types";

type Props = {
  languageSelector: LanguageSelectorProps & SelectorType;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  variant: SelectorVariant;
  company?: Companies;
  addTracking?: boolean;
};

const LanguageSelector = ({
  languageSelector, open, setOpen, variant, company, addTracking,
}: Props) => {
  const typographyVariant = variant === "header" ? "toolbarMenu" : "information";
  const id = `market_selector_button_${variant}`;

  const buttonClassName = cn(styles.button, {
    [styles.button__header]: variant === "header",
    [styles.button__footer]: variant === "footer",
  });

  const getColor = () : ColorToken => {
    if (variant === "footer") return "White";
    switch (company) {
    case "recycling":
      return "PrimaryGreen";
    case "metall":
      return "PrimaryBlue";
    case "aluminium":
    case "oil":
    case "steel":
      return "PrimaryGrey";
    default:
      return "PrimaryGreen";
    }
  };

  return (
    <Box>
      <button
        onClick={() => setOpen(!open)}
        className={buttonClassName}
        type="button"
        id={id}
        data-menu-type={addTracking ? "menu utilities" : undefined}
        data-menu-level={addTracking ? "1" : undefined}
        data-menu-text={addTracking ? languageSelector.current : undefined}
        data-menu-path={addTracking ? "" : undefined}
      >
        <Box
          color={getColor()}
          alignItems="center">
          <Typography variant={typographyVariant}>{languageSelector.current}</Typography>
          <Globe />
          {languageSelector.selectorType === "language" && <SelectIcon
            open={open}
            variant={variant} />}
        </Box>
      </button>
      {languageSelector.selectorType === "market" && (
        <MarketSelectorModal
          open={open}
          closeModal={() => setOpen(false)}
          logoUrl={languageSelector.logoUrl}
          logoAltText={languageSelector.logoAltText}
          header={languageSelector.header}
          items={languageSelector.items}
          closeModalLabel={languageSelector.closeModalLabel}
          addTracking={addTracking}
        />
      )}
      {languageSelector.selectorType === "language" && (
        <LanguageSelectorDropdown
          open={open}
          items={languageSelector.items}
          variant={variant}
          close={() => setOpen(false)}
          id={id}
          addTracking/>
      )}
    </Box>
  );
};

export default LanguageSelector;
