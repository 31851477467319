import Box from "@@/elements/Box";
import Icon, { IconTypes } from "@@/elements/Icons/Icon";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import SnwButton from "../Button/SnwButton";
import RichText from "../RichText";
import styles from "./index.module.scss";

export type ContactCardIconProps = {
  header?: string;
    body?: string;
    icon?: IconTypes;
  link?: LinkData;
  anchorId?: string;
  center?: boolean;
};

const ContactCardIcon = ({
  icon, header, body, link, anchorId, center,
} : ContactCardIconProps) => {
  return (
    <Box
      id={anchorId}
      br={1}
      width="full"
      height="full"
      backgroundColor="White"
      p={[6, 10, 10]}
      className={cn(styles.contact_card_icon, { [styles.contact_card_icon__center]: center })}>
      <EpiProperty<ContactCardIconProps> name="icon">
        {icon && (
          <Box
            mb={6}
            color="PrimaryBlue">
            <Icon
              height={56}
              name={icon} />
          </Box>
        )}
      </EpiProperty>
      <EpiProperty<ContactCardIconProps> name="header">
        {header && (
          <Typhography
            mb={6}
            color="PrimaryBlue"
            variant="heading4"
            asElement="h4">{header}</Typhography>
        )}
      </EpiProperty>
      <EpiProperty<ContactCardIconProps> name="body">
        {body && (
          <Box
            customStyle={{ maxWidth: "560px" }}
            className={cn(styles.contact_card_icon, { [styles.contact_card_icon__center]: center })}
            height="full"
            mb={6}>
            <RichText body={body} />
          </Box>
        )}
      </EpiProperty>
      {link && link.text && (
        <SnwButton
          variant="primary"
          link={link} />
      )}
    </Box>
  );
};

export type ProvidedContactCardIconProps =
  OptimizelyBlockProps<ContactCardIconProps> & ContactCardIconProps;

const ProvidedContactCardIcon = ({
  blockId,
  inEditMode,
  properties,
  anchorId,
  ...rest
}: ProvidedContactCardIconProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
    anchorId={anchorId}
  >
    {(optimizlyProperties) => (
      <ContactCardIcon
        anchorId={anchorId}
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedContactCardIcon;
