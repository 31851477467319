import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import QuickLinks, { QuickLinksProps } from "../QuickLinks";
import ContactCardIcon, { ContactCardIconProps } from "./card";
import styles from "./index.module.scss";

type ContactCardIconContainerProps = {
  header?: string,
  contactCards: ContactCardIconProps[],
  quickLinks?: QuickLinksProps;
  anchorId?: string;
};

const ContactCardIconContainer = ({
  header, contactCards, quickLinks, anchorId,
}: ContactCardIconContainerProps) => {
  return (
    <Box
      id={anchorId}
      backgroundColor="PrimaryBlueTint03">
      <Container>
        <EpiProperty<ContactCardIconContainerProps> name="header">
          {header && (<Typography
            mb={[10, 12, 14]}
            textAlign="center"
            variant="heading1"
            asElement="h2">{header}</Typography>)}
        </EpiProperty>
        <Box
          flexDirection="column"
          gap={14}>
          {quickLinks && (
            <EpiContent>
              <QuickLinks
                {...quickLinks}
                noGutter />
            </EpiContent>
          )}
          {contactCards && contactCards.length === 1 && (
            <EpiContent>
              <Box>
                {contactCards.map((card, index) => (
                  <ContactCardIcon
                    key={index}
                    icon={card.icon}
                    header={card.header}
                    body={card.body}
                    link={card.link}
                    anchorId={card.anchorId}
                    center />
                ))}
              </Box>
            </EpiContent>
          )}
          {contactCards && contactCards.length > 1 && (
            <EpiContent>
              <Box className={(contactCards.length === 2 || contactCards.length === 4) ? styles.grid : styles.cards_grid}>
                {contactCards.map((card, index) => (
                  <Box
                    component="span"
                    key={index}>
                    <ContactCardIcon
                      icon={card.icon}
                      header={card.header}
                      body={card.body}
                      link={card.link}
                      anchorId={card.anchorId} />
                  </Box>
                ))}
              </Box>
            </EpiContent>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export type ProvidedCompanyContactContainerProps =
  OptimizelyBlockProps<ContactCardIconContainerProps> & ContactCardIconContainerProps;

const ProvidedContactCardIconContainer = ({
  blockId,
  inEditMode,
  properties,
  anchorId,
  ...rest
}: ProvidedCompanyContactContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
    anchorId={anchorId}
  >
    {(optimizlyProperties) => (
      <ContactCardIconContainer
        anchorId={anchorId}
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedContactCardIconContainer;