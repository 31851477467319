import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPersonOutlined = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 23"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#3F4347"
      fillRule="evenodd"
      d="M.461 19.002a5.923 5.923 0 0 1 5.924-5.923h9.23a5.923 5.923 0 0 1 5.923 5.923v2.308c0 .722-.585 1.308-1.307 1.308H1.769A1.31 1.31 0 0 1 .461 21.31zm5.924-3.307a3.31 3.31 0 0 0-3.308 3.307v1h15.846v-1a3.31 3.31 0 0 0-3.308-3.307zM11 2.618a3.308 3.308 0 1 0 0 6.615 3.308 3.308 0 0 0 0-6.615M5.077 5.925a5.923 5.923 0 1 1 11.846 0 5.923 5.923 0 0 1-11.846 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPersonOutlined;
