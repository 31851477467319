import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { ArrowRight } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import TextButtonIcon from "../Button/TextButtonIcon";
import styles from "./index.modules.scss";

export type QuickLinksProps = {
  header?: string;
  links?: LinkData[];
  anchorId?: string;
  link: LinkData;
  noGutter?: boolean;
};

const QuickLinks = ({ header, links, link, anchorId, noGutter } : QuickLinksProps) => {

  return (
    <Container
      id={anchorId}
      pageGutter={noGutter ? 0 : undefined}>
      <Box>
        {header && (
          <EpiProperty<QuickLinksProps> name="header">
            <Typography
              mb={6}
              variant="heading6"
              asElement="h3"
              color="PrimaryBlue"
            >
              {header}
            </Typography>
          </EpiProperty>
        )}
        <Box className={styles.quick_links_container}>
          {links && links.map((quickLink, index) => (
            <a
              key={index}
              className={cn(styles.quick_link, { [styles.quick_link__grow]: links.length > 2 } )}
              {...quickLink}>
              <Typography
                textAlign="center"
                variant="button"
                asElement="span">{quickLink.text}</Typography>
            </a>
          ))}
          {link && link.href && link.text && (
            <Box flexShrink={0}>
              <TextButtonIcon
                dense
                direction="right"
                link={link} >
                <ArrowRight />
              </TextButtonIcon>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export type ProvidedQuickLinksProps = OptimizelyBlockProps<QuickLinksProps> & QuickLinksProps;

const ProvidedQuickLinks = ({
  blockId,
  inEditMode,
  properties,
  anchorId,
  ...rest
}: ProvidedQuickLinksProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
    anchorId={anchorId}
  >
    {(optimizlyProperties) => <QuickLinks
      {...rest}
      {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedQuickLinks;