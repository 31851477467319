import Box from "@@/elements/Box";
import styles from "./EditorAnchorHelper.module.scss";

type EditorAnchorHelperProps = {
  anchorId?: string;
  inEditMode: boolean;
};
const EditorAnchorHelper = ({ anchorId, inEditMode }: EditorAnchorHelperProps) => {
  if (inEditMode && anchorId) {
    return (
      <Box className={styles.editorAnchor_container}>
        <p className={styles.editorAnchor}>{`#${anchorId}`}</p>
      </Box>
    );
  }
};

export default EditorAnchorHelper;