import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Icon from "@@/elements/Icons/Icon";
import {
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  Close,
  MenuIcon,
  SearchIcon,
} from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointAndAbove,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import useScrollDirection from "@@/hooks/use-scroll-direction";
import { useScrollLock } from "@@/hooks/use-scroll-lock";
import useScrollPosition from "@@/hooks/use-scroll-position";
import cn from "classnames";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { TopMenuMobile } from "../TopMenu";
import { MenuProps } from "../headerProps";
import Accordion from "./Accordion";
import Drawer from "./Drawer";
import MainMenuSearch from "./Search";
import styles from "./index.module.scss";

type Props = MenuProps;
const MainMenu = ({
  logoUrl,
  logoAltText,
  logoLink,
  logoSize = "large",
  navigationItems,
  company,
  mobileOnlyMenu,
  searchData,
}: Props) => {
  const [positionX, setPositionX] = useState<number>();
  const [currentMenu, setCurrentMenu] = useState<number | undefined>(undefined);
  const [currentSubMenu, setCurrentSubMenu] =
    useState<number | undefined>(undefined);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [drawer1, setDrawer1] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [currentDrawerMenu, setCurrentDrawerMenu] =
    useState<number | undefined>(undefined);
  const ref = useRef<HTMLDivElement>();
  const subMenuRef = useRef<HTMLDivElement>();
  const drawerRef = useRef<HTMLDivElement>();
  const drawerRef1 = useRef<HTMLDivElement>();
  const searchRef = useRef<HTMLDivElement>();
  const scrollDirection = useScrollDirection();
  const scrollPosition = useScrollPosition();
  const { lock, unlock } = useScrollLock();
  const isLarge = useIsBreakpointAndAbove(ScreenSize.LARGE);
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  const resetMenu = () => {
    document.documentElement.style.setProperty(
      "--dropdown-translate-x",
      "0px",
    );
    document.documentElement.style.setProperty(
      "--dropdown-translate-y",
      "-100px",
    );
    setCurrentMenu(undefined);
  };

  const handleClickOutside = (event: any) => {
    if (!ref.current?.contains(event.target) &&
      !subMenuRef.current?.contains(event.target)) {
      resetMenu();
    }
  };

  const closeDrawer = (event: any) => {
    if (
      !drawerRef.current?.contains(event.target) &&
      !drawerRef1.current?.contains(event.target) &&
      !searchRef.current?.contains(event.target) &&
      (drawer || drawer1)
    ) {
      setDrawer(false);
      setDrawer1(false);
      setCurrentDrawerMenu(undefined);
      unlock();
    }
  };
  useEffect(() => {
    const menuElement = document.querySelector("#main-menu");
    menuElement.parentElement?.classList.add("main-menu");
  }, []);

  useEffect(() => {
    const menuElement = document.querySelector("#main-menu");
    const searchMobileNotOpen = isLarge || !searchOpen;
    const menuNotOpen = !(drawer || drawer1);
    const hideMenu = isSmall ? scrollPosition > 100 : scrollPosition > 150;

    if (hideMenu && scrollDirection !== "down") {
      menuElement.parentElement?.classList.add("main-menu-sticky");
    } else if (scrollPosition === 0) {
      menuElement.parentElement?.classList.remove("main-menu-sticky");
    }

    if (scrollDirection === "down" && searchMobileNotOpen && menuNotOpen && hideMenu) {
      menuElement.parentElement?.classList.add("main-menu-hide");
    }
    if (scrollDirection === "up" && searchMobileNotOpen && menuNotOpen) {
      menuElement.parentElement?.classList.remove("main-menu-hide");
    }
  }, [scrollDirection, scrollPosition]);

  useEffect(() => {
    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, [drawerRef, searchRef, drawer, drawer1]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const onOpenSearch = (open, event = null) => {
    if (
      event &&
      (drawerRef.current?.contains(event.target) ||
        drawerRef1.current?.contains(event.target))
    ) {
      return;
    }
    setSearchOpen(open);
    if (open) {
      resetMenu();
      if (!isLarge) {
        lock();
      }
      document.getElementById("main_menu_search_input").focus();
    } else {
      document.getElementById("main_menu_search_input").blur();
      unlock();
    }
  };

  const openMenu = (event: MouseEvent, index: number): void => {
    setCurrentSubMenu(undefined);
    setCurrentMenu(undefined);
    document.documentElement.style.setProperty(
      "--dropdown-translate-y",
      "-100px",
    );
    if (currentMenu !== index) {
      const posX = Math.min(
        window.scrollX + event.currentTarget.getBoundingClientRect().left,
        window.innerWidth - 350,
      );
      setTimeout(() => {
        setPositionX(
          posX,
        );
        document.documentElement.style.setProperty(
          "--dropdown-translate-x",
          "0px",
        );
        setCurrentMenu(index);
        document.documentElement.style.setProperty(
          "--dropdown-translate-y",
          "0px",
        );
      }, currentMenu !== undefined && currentMenu !== index ? 250 : 0);
    }
  };

  const openDrawerMenu = (index: number): void => {
    setCurrentDrawerMenu(index);
    setDrawer1(true);
  };

  const openDrawer = (): void => {
    setDrawer(true);
    lock();
  };

  const openSubMenu = (event: MouseEvent, index: number): void => {
    const initialMenuPositionX = document
      .querySelectorAll(".main-menu-item")
      [currentMenu].getBoundingClientRect().left;

    if (currentSubMenu === undefined) {
      setPositionX(
        Math.min(window.scrollX + initialMenuPositionX, window.innerWidth - 500),
      );
    }

    if (currentSubMenu === index) {
      setPositionX(
        Math.min(window.scrollX + initialMenuPositionX, window.innerWidth - 500),
      );
      setCurrentSubMenu(undefined);
      document.documentElement.style.setProperty(
        "--dropdown-translate-x",
        "0px",
      );
    } else {
      setCurrentSubMenu(index);
      document.documentElement.style.setProperty(
        "--dropdown-translate-x",
        "-150px",
      );
    }
  };
  const dropDownPosition = {
    left: positionX,
  };
  const dropdownWidth =
    currentSubMenu !== undefined ? cn(styles.full) : cn(styles.half);
  // Classnames
  const dropDownClassNames = cn(styles.dropdown, dropdownWidth, ["dropdown"]);
  const subMenuClassNames = cn(styles.submenu, {
    [styles[`submenu_${company}`]]: company,
  });
  const submenu1ClassNames = cn(styles.menu_items, styles.menu_items_submenu1, {
    [styles[`menu_items_${company}`]]: company,
  });

  const linkClassNames = cn(styles.menu_items, {
    [styles[`menu_items_${company}`]]: company,
  });
  const active = cn(styles.menu_items_active, {
    [styles[`menu_items_active_${company}`]]: company,
  });
  const listingClassNames = cn(styles.listing_item, {
    [styles[`listing_item_${company}`]]: company,
  });
  const currentClass = cn(styles.button, styles.button_current);

  const logoClassNames = cn(styles.logo, {
    [styles.logo__small]: logoSize === "small",
  });

  const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);

  useEffect(() => {
    if (!drawer1) setActiveSubMenuItem(null);
  }, [drawer1]);

  const getDrawerSubMenu = () => {
    if (
      currentDrawerMenu !== undefined &&
      currentDrawerMenu < navigationItems.length
    ) {
      return (
        <ul className={styles.accordion_list}>
          {navigationItems[currentDrawerMenu].children.map((element, index) => (
            <Box
              component="li"
              width="full"
              key={index}>
              {element.children.length > 0 ? (
                <Box
                  width="full"
                  mb={0.5}>
                  <Accordion
                    company={company}
                    menuChoice={element}
                    open={index === activeSubMenuItem}
                    onClick={() => {
                      if (activeSubMenuItem === index) {
                        setActiveSubMenuItem(null);
                      } else setActiveSubMenuItem(index);
                    }}
                  />
                </Box>
              ) : (
                <a
                  href={element.link}
                  key={index}
                  className={linkClassNames}
                  data-menu-type={"menu drawer"}
                  data-menu-level={element.depth}
                  data-menu-text={element.name}
                  data-menu-path={element.link}>
                  <Typography variant="submenu1">{element.name}</Typography>
                </a>
              )}
            </Box>
          ))}
        </ul>
      );
    }
    // GET MARKET SELECTOR
    if (
      currentDrawerMenu !== undefined &&
      currentDrawerMenu >= navigationItems.length
    ) {
      return (
        <ul className={styles.market_container}>
          {mobileOnlyMenu.languageSelector.header && (
            <Typography
              px={7.5}
              pb={3}
              pt={2}
              variant="submenu1">
              {mobileOnlyMenu.languageSelector.header}
            </Typography>
          )}
          {mobileOnlyMenu.languageSelector.items &&
            mobileOnlyMenu.languageSelector.items.map((item, index) => (
              <li
                key={index}
                className={
                  item.active
                    ? cn(styles.active_item, {
                      [styles[`active_item_${company}`]]: company,
                    })
                    : cn(styles.market_item, {
                      [styles[`market_item_${company}`]]: company,
                    })
                }
              >
                <a
                  href={item.link.href}
                  target={item.link.target}
                  title={item.link.title}
                  data-menu-type={"menu utilities"}
                  data-menu-level={"2"}
                  data-menu-text={item.link.text}
                  data-menu-path={item.link.href}
                >
                  <Typography variant="menu">{item.link.text}</Typography>
                </a>
              </li>
            ))}
        </ul>
      );
    }
    return <></>;
  };
  return (
    <>
      {drawer && <Box className={styles.overlay} />}
      <Container
        id="main-menu"
        size="full"
        pageGutter={0}>
        <nav className={styles.main_container}>
          <Box
            className={styles.content_width}
            ref={ref}>
            <a
              className={logoClassNames}
              href={logoLink.href}>
              <img
                src={logoUrl}
                alt={logoAltText} />
            </a>
            <ul>
              {navigationItems &&
                navigationItems.map((menuItem, index) => (
                  <li
                    className={styles.list_item}
                    key={index}>
                    <button
                      aria-label="open menu"
                      type="button"
                      aria-haspopup="menu"
                      key={index}
                      className={cn(
                        index === currentMenu ? currentClass : styles.button,
                        "main-menu-item",
                      )}
                      onClick={(event) => openMenu(event, index)}
                      data-menu-type={"menu drawer"}
                      data-menu-level={menuItem.depth}
                      data-menu-text={menuItem.name}
                      data-menu-path=""
                    >
                      <Typography
                        key={menuItem.name}
                        variant="menu">
                        {menuItem.name}
                      </Typography>
                    </button>
                  </li>
                ))}
            </ul>
            <div className={styles.flex}>
              {mobileOnlyMenu.mobileItems && mobileOnlyMenu.mobileItems.map((mobileItem, index) => {
                return (
                  <a
                    key={index}
                    className={cn(styles.mobile_link, "mobile_link__icon", `icon-${mobileItem.icon}`)}
                    href={mobileItem.link.href}
                    title={mobileItem.link.title}
                    target={mobileItem.link.target}
                    data-menu-type={"menu drawer"}
                    data-menu-level={"1"}
                    data-menu-text={mobileItem.link.text}
                    data-menu-path={mobileItem.link.href}
                  >
                    <Icon
                      name={mobileItem.icon}
                      title={mobileItem.iconAltText}
                      height={20}
                      width={20}
                    />
                  </a>
                  
                );
              })}
              
              <button
                aria-label="search"
                type="button"
                id="search_button"
                className={styles.search}
                onClick={() => onOpenSearch(!searchOpen)}
                data-menu-type={"menu drawer"}
                data-menu-level={"1"}
                data-menu-text={"search"}
                data-menu-path=""
              >
                <SearchIcon />
              </button>
              <button
                type="button"
                aria-label="open menu"
                onClick={openDrawer}
                className={styles.hamburger}
                aria-haspopup="menu"
              >
                <MenuIcon />
              </button>
              {/* SEARCH */}
              <div ref={searchRef}>
                <MainMenuSearch
                  open={searchOpen}
                  setOpen={onOpenSearch}
                  searchData={searchData}
                />
              </div>
            </div>
          </Box>
        </nav>
        <div
          className={cn(
            currentMenu === undefined ? styles.closed : "",
            dropDownClassNames,
          )}
          style={dropDownPosition}
          ref={subMenuRef}
        >
          <ul className={styles.submenu}>
            {navigationItems
              .map((navItem, navItemIndex) =>
                navItem.children.map((child) => ({ ...child, navItemIndex })),
              )
              .map((children) =>
                children.map((submenu, index) => (
                  <Box
                    key={index}
                    width="full"
                    className={
                      submenu.navItemIndex === currentMenu ? "" : styles.hidden
                    }
                  >
                    {submenu.children.length > 0 ? (
                      <button
                        type="button"
                        onClick={(event) => openSubMenu(event, index)}
                        key={index}
                        className={`${
                          index === currentSubMenu ? active : null
                        } ${submenu1ClassNames}`}
                        data-menu-type={"menu drawer"}
                        data-menu-level={submenu.depth}
                        data-menu-text={submenu.name}
                        data-menu-path=""
                      >
                        <Typography variant="submenu1">
                          {submenu.name}
                        </Typography>
                        <ChevronRight />
                      </button>
                    ) : (
                      <a
                        href={submenu.link}
                        key={index}
                        className={submenu1ClassNames}
                        data-menu-type={"menu drawer"}
                        data-menu-level={submenu.depth}
                        data-menu-text={submenu.name}
                        data-menu-path={submenu.link}
                      >
                        <Typography variant="submenu1">
                          {submenu.name}
                        </Typography>
                      </a>
                    )}
                  </Box>
                )),
              )}
          </ul>
          <ul
            className={cn(
              subMenuClassNames,
              currentSubMenu !== undefined ? "" : styles.hidden,
            )}
          >
            {navigationItems
              .map((navItem, navItemIndex) =>
                navItem.children.map((child) => ({ ...child, navItemIndex })),
              )
              .map((children) =>
                children.map((submenu, subMenuIndex) =>
                  submenu.children
                    .map((child) => ({
                      ...child,
                      navItemIndex: submenu.navItemIndex,
                      subMenuIndex,
                    }))
                    .map((submenu2, index) => {
                      if (index === 0) {
                        return (
                          <Box
                            pl={10}
                            className={cn(
                              styles.list_item,
                              submenu2.navItemIndex === currentMenu &&
                                submenu2.subMenuIndex === currentSubMenu
                                ? ""
                                : styles.hidden,
                            )}
                            component="li"
                            width="full"
                            key={index}
                          >
                            <a
                              href={submenu2.link}
                              className={listingClassNames}
                              data-menu-type={"menu drawer"}
                              data-menu-level={submenu2.depth}
                              data-menu-text={submenu2.name}
                              data-menu-path={submenu2.link}
                            >
                              <Typography variant="submenu2">
                                {submenu2.name}
                              </Typography>
                              <ArrowRight />
                            </a>
                          </Box>
                        );
                      }

                      return (
                        <Box
                          key={index}
                          className={cn(
                            styles.list_item,
                            submenu2.navItemIndex === currentMenu &&
                              submenu2.subMenuIndex === currentSubMenu
                              ? ""
                              : styles.hidden,
                          )}
                          component="li"
                          width="full"
                        >
                          <a
                            href={submenu2.link}
                            key={index}
                            className={`${
                              submenu2.active && styles.current_page
                            } ${styles.menu_items}`}
                            data-menu-type={"menu drawer"}
                            data-menu-level={submenu2.depth}
                            data-menu-text={submenu2.name}
                            data-menu-path={submenu2.link}
                          >
                            <Typography variant="submenu2">
                              {submenu2.name}
                            </Typography>
                          </a>
                        </Box>
                      );
                    }),
                ),
              )}
          </ul>
        </div>
      </Container>
      {/* TABLET AND MOBILE */}
      <Drawer
        ref={drawerRef}
        open={drawer}
        className={styles.drawer}>
        <div>
          <div className={styles.icon_container}>
            <button
              aria-label="go back"
              type="button"
              onClick={() => {
                setCurrentDrawerMenu(undefined);
              }}
            />

            <button
              type="button"
              aria-label="close"
              onClick={() => {
                setDrawer(false);
                unlock();
                setCurrentDrawerMenu(undefined);
              }}
            >
              {currentDrawerMenu === undefined ? <Close /> : <Box pt={6} />}
            </button>
          </div>

          {/* {currentDrawerMenu === undefined && ( */}
          <ul className={styles.mobile_container}>
            {navigationItems &&
              navigationItems.map((menuItem, index) => (
                <Box
                  component="li"
                  width="full"
                  key={index}>
                  <button
                    aria-label={menuItem.name}
                    type="button"
                    aria-haspopup="menu"
                    key={index}
                    className={`${index === currentMenu && currentClass} ${cn(
                      styles.menu_items,
                      styles.menu_items_submenu1,
                    )}`}
                    onClick={() => openDrawerMenu(index)}
                    data-menu-type={"menu drawer"}
                    data-menu-level={menuItem.depth}
                    data-menu-text={menuItem.name}
                    data-menu-path=""
                  >
                    <Typography
                      key={menuItem.name}
                      variant="menu">
                      {menuItem.name}
                    </Typography>
                    <ChevronRight />
                  </button>
                </Box>
              ))}
          </ul>
        </div>

        <div>
          <TopMenuMobile
            onClick={() => {
              setDrawer1(true);
              setCurrentDrawerMenu(navigationItems.length);
            }}
            languageSelector={mobileOnlyMenu.languageSelector}
            company={company}
            items={mobileOnlyMenu.items}
            mobileButton={mobileOnlyMenu.mobileButton}
          />
        </div>
      </Drawer>
      <Drawer
        ref={drawerRef1}
        open={drawer1}
        className={styles.drawer1}>
        <div>
          <div className={styles.icon_container}>
            <button
              aria-label="go back"
              type="button"
              onClick={() => {
                setCurrentDrawerMenu(undefined);
                setDrawer1(false);
              }}
            >
              {currentDrawerMenu !== undefined && <ChevronLeft />}
            </button>

            <button
              aria-label="close"
              type="button"
              onClick={() => {
                setDrawer(false);
                setDrawer1(false);
                unlock();
                setCurrentDrawerMenu(undefined);
              }}
            >
              <Close />
            </button>
          </div>
          {getDrawerSubMenu()}
        </div>
      </Drawer>
    </>
  );
};

export default MainMenu;
