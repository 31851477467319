import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import Faq, { FaqProps } from "../Faq";
import QuickLinks, { QuickLinksProps } from "../QuickLinks";
import styles from "./index.module.scss";

type FaqContainerProps = {
  header?: string,
  items: FaqProps[],
  quickLinks?: QuickLinksProps;
  anchorId?: string;
  backgroundColor: "white" | "grey" | "blue";
};

const ContactCardIconContainer = ({
  header, items, quickLinks, anchorId, backgroundColor,
}: FaqContainerProps) => {
  return (
    <Box
      id={anchorId}
      className={cn(styles.faq_container, styles[`faq_container_${backgroundColor}`])}>
      <Container>
        <EpiProperty<FaqContainerProps> name="header">
          {header && (<Typography
            mb={[10, 12, 14]}
            textAlign="center"
            variant="heading1"
            asElement="h2">{header}</Typography>)}
        </EpiProperty>
        <Box
          flexDirection="column"
          gap={[10, 12, 14]}>
          {quickLinks && (
            <EpiContent>
              <QuickLinks
                {...quickLinks}
                noGutter />
            </EpiContent>
          )}
          {items && items.map((item, index) => (
            <Box
              component="span"
              key={index}>
              <EpiContent>
                <Faq
                  {...item}
                  backgroundColor={backgroundColor === "grey" ? "transparent" : backgroundColor}
                  noGutter />
              </EpiContent>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export type ProvidedFaqContainerProps =
  OptimizelyBlockProps<FaqContainerProps> & FaqContainerProps;

const ProvidedFaqContainer = ({
  blockId,
  inEditMode,
  properties,
  anchorId,
  ...rest
}: ProvidedFaqContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
    anchorId={anchorId}
  >
    {(optimizlyProperties) => (
      <ContactCardIconContainer
        anchorId={anchorId}
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedFaqContainer;